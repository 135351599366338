import { ModelAbstract } from '../../../services/model.abstract';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Helpers } from '~/src/app/services/helpers';
import { Token } from '~/src/app/services/token';
import { MatDialog } from '@angular/material/dialog';
import obj2fd from 'obj2fd';
import { Data } from '@angular/router';
import { LoggedUser } from '~/src/app/services/logged-user';
@Injectable({ providedIn: 'root' })
export class OrganisationService extends ModelAbstract {
    public lastUsedFilters;
    public socialSites;
    constructor(private http: HttpClient, dialog: MatDialog) {
        super(http, dialog);

        this.apiLink = '/api/organization';
    }

    getOrganization() {
        return this.http.get(
            this.apiLink + '/organization-tree',
            Helpers.getBaseHttpHeaders(Token.getToken())
        ).toPromise();
    }


    getOrganizationGroupBy(filters: any = []): Promise<any> {
        const options = Helpers.getBaseHttpHeaders(Token.getToken());
    
        // this.lastUsedFilters = filters;
        // console.log('filters', filters);
        const filtersData = [];
        const orgData = [];
        const tokenStatus = [];
        if (filters) {
            if (filters['organization'] !== null && filters['organization'] !== undefined && filters['organization'] !== '') {
                orgData.push(filters['organization'].value);
            }
            if (filters['tokenStatus']?.tokenStatus !== undefined && filters['tokenStatus'].tokenStatus !== null && filters['tokenStatus'].tokenStatus !== '') {
                tokenStatus.push(filters['tokenStatus'].tokenStatus);
            }
        }
        if (Array.isArray(filters) && filters['selectPlatform']) {
            filtersData.push(filters['selectPlatform']);
        }
        // Combine all parameters into a single `params` object
        options['params'] = {
            ...(orgData.length > 0 && { organization: orgData }),
            ...(tokenStatus.length > 0 && { tokenStatus: tokenStatus }),
            ...(filtersData.length > 0 && { socialTypes: filtersData }),
        };
        if (tokenStatus.length === 0 || tokenStatus === undefined) {
            delete options['params'].tokenStatus;
        }
        return this.http.get(this.apiLink + '/organizationGroupBy', options)
            .toPromise()
            .then(res => {
                return Promise.resolve(res);
            });
    }

    getGroupByOrganization(filters: any = []): Promise<any> {
        const options = Helpers.getBaseHttpHeaders(Token.getToken());

        // this.lastUsedFilters = filters;

        const filtersData = [];
        const orgData = [];
        const tokenStatus = [];
    
        if (filters) {
            if (filters['organization'] !== null && filters['organization'] !== undefined && filters['organization'] !== '') {
                orgData.push(filters['organization'].value);
            }
            if (filters['tokenStatus']?.tokenStatus !== undefined && filters['tokenStatus'].tokenStatus !== null && filters['tokenStatus'].tokenStatus !== '') {
                tokenStatus.push(filters['tokenStatus'].tokenStatus);
            }
        }
        if (Array.isArray(filters) && filters['selectPlatform']) {
            filtersData.push(filters['selectPlatform']);
        }
    
        // Combine all parameters into a single `params` object
        options['params'] = {
            ...(orgData.length > 0 && { organization: orgData }),
            ...(tokenStatus.length > 0 && { tokenStatus: tokenStatus }),
            ...(filtersData.length > 0 && { socialTypes: filtersData }),
        };
       
        if (tokenStatus.length === 0 || tokenStatus === undefined) {
            delete options['params'].tokenStatus;
        }

        return this.http.get(this.apiLink + '/groupByorganization', options).toPromise().then(res => {

            if (res) {
                this.socialSites = res['Organization'];
            }
            return Promise.resolve(res);
        });
    }

    deleteOrganization(organizationID: number) {
        return this.http.delete(this.apiLink + `/${organizationID}`, Helpers.getBaseHttpHeaders(Token.getToken())).toPromise();
    }
}
